(function($) {
  var defaultShowCount = 10;
  var $contentListTable;
  var directoryTable = document.getElementById('directory-table');
  // init card table
  $('.content-list-table-content').cardtable();

  //classes from desktop and mobiel table
  let $desktopRows = $('.stacktable.large-only').find('.results-row-hover');
  let $mobileTables = $('.stacktable.small-only').find('.results-row-hover');;

  //function onclik
  $desktopRows.click(handleMemberClick);
  $mobileTables.click(handleMemberClick);

  //member-directory - sort table
  if(directoryTable != null){
    new Tablesort(directoryTable);
  }

  // content-list-table node is copied for desktop/mobile
  // so we query here
  $contentListTable = $('.content-list-table');

  $contentListTable.each(initExpandableTable);

  function initExpandableTable() {
    var $this = $(this);
    var currentLastRowIndex = defaultShowCount;
    var visualLastChildSmall, rows;

    // get count or tbody trs for desktop
    var $rowsLarge = $this.children('.content-list-table-content')
      .not('.small-only')
      .children('tbody')
      .children('tr');
    var rowsLargeLength = $rowsLarge.length;

    // get count of content-list-table-content.small for mobile
    var $rowsSmall = $this.find('.content-list-table-content.small-only');
    var rowsSmallLength = $rowsSmall.length;

    // just for simplicity
    rows = rowsSmallLength;

    // if either is 0 we can leave
    if (
      !rowsLargeLength
      || !rowsSmallLength
      || rowsSmallLength <= defaultShowCount
    ) {
      return;
    }

    updateExpandableTableUI();

    // attach click event to show more, update currentLastRow
    $this.find('.show-more').click(function(e) {
      var $rowsSmallToShow, $rowsLargeToShow;
      e.preventDefault();
      // increment the index
      currentLastRowIndex = currentLastRowIndex + defaultShowCount;
      // do this twice so we keep desktop and mobile nsync
      // slice doesn't care if second param yields undefined index
      $rowsSmallToShow = $rowsSmall.slice(0, currentLastRowIndex);
      $rowsSmallToShow.addClass('shown');
      $rowsLargeToShow = $rowsLarge.slice(0, currentLastRowIndex);
      $rowsLargeToShow.addClass('shown');

      updateExpandableTableUI();
    });

    function updateExpandableTableUI() {
      // remove this since it's not the index no more
      if (visualLastChildSmall) {
        $(visualLastChildSmall).removeClass('visual-last-child');
      }

      if (currentLastRowIndex < rows) {
        // update UI to denote we have more than 10 records
        $this.addClass('content-list-table-expandable');
        // our CSS has a style on last child so we
        // need to update visual last child as we go through
        visualLastChildSmall = $rowsSmall.get(currentLastRowIndex - 1);
        $(visualLastChildSmall).addClass('visual-last-child');
      }
      else {
        $this.removeClass('content-list-table-expandable');
      }
    }
  }

  //function built to make mobile table rows clicable
  // function handleMemberClick(event){
  //   if($(event.currentTarget).is("tr")){
  //      var hasMemberId = $(event.currentTarget).hasClass('memberId')
  //      var hasProviderId = $(event.currentTarget).hasClass('providerId')

  //      if(hasMemberId) {
  //       location.href="/member-detail?memberId=" + $(event.currentTarget).data('id');
  //      }
  //      else if (hasProviderId) {
  //       location.href="/ACOEM-Find-a-Provider-Detail?memberId=" + $(event.currentTarget).data('id');
  //      }
  //   }
  // };


  function handleMemberClick(event){
    var regex = /memberId-\d+/gm;
    var classes = event.currentTarget.classList;

    if($(event.currentTarget).is("table")){
       classes = $(event.currentTarget).find("tr")[0].classList;
    }

    for(var i=0; i<classes.length; i++){

      if($(event.currentTarget).hasClass('provider')){
        if(regex.exec(classes[i])){
          location.href="/ACOEM-Find-a-Provider-Detail?memberId=" + classes[i].split('-')[1];
        };

      }else{

        if(regex.exec(classes[i])){
          location.href="/member-detail?memberId=" + classes[i].split('-')[1];
        }
    };
  };
};
})(jQuery);
