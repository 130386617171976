/*
  Handles replacing input type="submit" with button elem
  and submit of form form header search input
 */
(function($, win) {
  var searchIcon = '<i class="fas fa-search"></i>';
  var searchSrLabel = '<span class="sr-only">Submit</span>';
  // elements to replace
  var $searchPageSubmit = $('.search-results-header-search input[type="submit"]');
  var $searchHeaderSubmit = $('.header-search input[type="submit"]');

  // replace them
  replaceWithButton($searchPageSubmit);
  replaceWithButton($searchHeaderSubmit);
  attachHeaderSearchSubmitClick();

  function replaceWithButton(elem) {
    // Rebuild the button given an input type="submit"
    // to show the search icon
    var $button = $('<button></button>');

    $button.attr('type', elem.attr('type'))
      .attr('name', elem.attr('name'))
      .attr('value', elem.attr('value'))
      .attr('id', elem.attr('id'))
      .addClass('search-btn')
      .append(searchIcon)
      .append(searchSrLabel);

    elem.replaceWith($button);
  }

  // since we may rebuild the input to a button we attach
  // the event after replaceWithButtonCalled
  function attachHeaderSearchSubmitClick() {
    var $searchHeaderSubmitButton = $('.header-search .search-btn');
    var $searchHeaderInput = $('.header-search .search-input');

    // handle the click
    $searchHeaderSubmitButton.on('click', function(e) {
      var searchTerm;
      e.preventDefault();
      // trim just for fun
      searchTerm = $searchHeaderInput.val().trim();
      if (searchTerm.length) {
        win.location.href = generateSearchURL(searchTerm);
      }
      return;
    });

  }

  // build query param
  function generateSearchURL(searchtext) {
    return '/search?searchtext='
           + searchtext
           + '&searchmode=allwords';
  }

})(jQuery, window);
