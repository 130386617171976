(function($) {

  /* implements icheck checkbox and radio
  @see https://github.com/fronteed/iCheck
  */

  //member directory has custom checkboxes
  $('input').not('.checkbox-tag').iCheck({
    checkboxClass: 'icheckbox_square',
    radioClass: 'iradio_square'
  });

  $('.js-multiple-select').select2({
    placeholder: 'Select'

  });

  $('#reset-btn').click(function(){
    $('.js-multiple-select').val(null).trigger('change');
  });


var formRefresh = true;
var isBack;
var isValue;

$(document).ready(function(){

  if(document.getElementsByClassName('result-provider').length > 0){
     document.getElementsByClassName('result-provider')[0].style.display = "none";
  }
  //console.log("onload - " + formRefresh)
  $('.js-multiple-select').select2({
    placeholder: 'Select'
  });
  var search = document.getElementsByClassName("formSearch");
  var results = document.getElementById("searchResults");
  var session = document.getElementsByClassName("form-control");
  var checkboxes = document.getElementsByClassName("checkbox-tag");
  isValue = false;
  isBack = false;


  for(var i=0; i<checkboxes.length; i++){
    if(checkboxes[i].checked == true){
      isValue = true;
    }
  }

  for(var j=0; j<session.length; j++){
    if(session[j].value != ""){
      isValue = true;
    }
  }

  //for(var k=0; k<session.length; k++){

    //if(session[k].classList.contains("js-multiple-select")){

      //if(session[k].selectedOptions.length > 0){
        //isValue = true;
      //}
    //}
  //}


   //SHOW RESULTS AND HIDE FORM
   if(typeof search != null && isValue == true && isBack == false){
     search[0].style.display = "none";
     results.style.display = "block";
     if(document.getElementsByClassName('result-provider').length > 0){
      document.getElementsByClassName('result-provider')[0].style.display = "block";
      }

      if(formRefresh){
        //document.getElementsByTagName("form")[0].submit()
        //document.cookie = "reload=true";
        //console.log("if form - " + formRefresh)
      }
    }

     if(isValue == false && window.location.search == "?param=refresh"){
      window.history.pushState(null, null, window.location.pathname);
      location.reload();
     }

     if(typeof search != null && isValue == true && window.location.search == "?param=refresh"){

      $('#reset-btn').trigger("click");
      $('#mdSubmit').trigger("click");

     }

   });




// function backToFilters(){
//   var search = document.getElementsByClassName("formSearch");
//   var results = document.getElementById("searchResults");
//   var session = document.getElementsByClassName("form-control");
//   isBack = true;

//   if(isBack == true){
//      search[0].style.display = "block";
//      results.style.display = "none";
//   }
//   backWithSession();
//      $('.js-multiple-select').select2({
//     placeholder: 'Select'

//   });
// }

var search = document.getElementsByClassName("formSearch");

//BACK TO FORM
$('#backFilters').click(function(){

  var results = document.getElementById("searchResults");
  var session = document.getElementsByClassName("form-control");
  isBack = true;

  if(isBack == true){
     search[0].style.display = "block";
     results.style.display = "none";
     if(document.getElementsByClassName('result-provider').length > 0){
      document.getElementsByClassName('result-provider')[0].style.display = "none";
   }
  }
  backWithSession();
     $('.js-multiple-select').select2({
    placeholder: 'Select'
  });
});

//BACK TO RESULTS MEMBER DIRECTORY
$('#backLatestMember').click(function(){
  location.href="/member-directory";
  if(typeof search !=null){
     search[0].style.display = "none";
     document.getElementById("searchResults").style.display = "block";
  }
});

//BACK TO RESULTS FIND A PROVIDER
$('#backLatestProvider').click(function(){
  location.href="/ACOEM-Find-a-Provider";
  if(typeof search !=null){
     search[0].style.display = "none";
     document.getElementById("searchResults").style.display = "block";
  }
});

$('[id="reset-btn"]').click(function(){
  var session = document.getElementsByClassName("form-control");

  for(var i=0; i<session.length; i++){
    if(session[i].value != null){
      session[i].value = null;
    }
  }

  session = document.getElementsByClassName("checkbox-tag");
  for(var l = 0; l < session.length; l++)
  {
    session[l].checked = false;
  }

  $('#mdSubmit').trigger("click");

  });


  //clear session on "Search Again" link click
  $('#backFiltersMember').click(function(){
    location.href="/member-directory?param=refresh";
  });

    //clear session on "Search Again" link click
    $('#backFiltersProvider').click(function(){
      location.href="/ACOEM-Find-a-Provider?param=refresh";
    });

  //deleting filter from result page (multiselectors)
  $('.deleteMultiFilter').click(function(){
    //get filter value on (x) click
    var getFilterValue = $(this).parent()[0].children[0].innerText;
    console.log(getFilterValue);
    var allSelectedFilters = document.getElementsByClassName("select2-selection__choice");
    //loop through all selected filters
    for(var i=0; i<allSelectedFilters.length;i++){
      var title = allSelectedFilters[i].title;
      if(getFilterValue == title){
        allSelectedFilters[i].children[0].click();
      }
    }
    $('#mdSubmit').trigger("click");
  });

  //deleting filter from result page (checkboxes)
  $('.deleteCheckboxFilter').click(function(){
     //get filter value on (x) click
     var getFilterValue = $(this).parent()[0].children[0].innerText;
     console.log(getFilterValue);
     var allSelectedFilters = document.getElementsByClassName("checkbox-directory-item");
     //loop through all selected filters
     for(var i=0; i<allSelectedFilters.length;i++){
       var childValue = allSelectedFilters[i].children[0].value;
       if(getFilterValue == childValue){
         allSelectedFilters[i].children[0].click();
       }
     }

    $('#mdSubmit').trigger("click");
  });


  //deleting filter from result page (input fields)
  $('.deleteFNFilter').click(function(){
    if((window.location.href).indexOf("ember-") > 0){
    document.getElementsByName("p$lt$ctl02$pageplaceholder$p$lt$ctl00$MemberDirectory$mdFirstName")[0].value = "";
    }else{
    document.getElementsByName("p$lt$ctl02$pageplaceholder$p$lt$ctl00$FindAProvider$mdFirstName")[0].value = "";
    }

    $('#mdSubmit').trigger("click");
  });

  $('.deleteLNFilter').click(function(){
    if((window.location.href).indexOf("ember-") > 0){
    document.getElementsByName("p$lt$ctl02$pageplaceholder$p$lt$ctl00$MemberDirectory$mdLastName")[0].value = "";
    }else{
    document.getElementsByName("p$lt$ctl02$pageplaceholder$p$lt$ctl00$FindAProvider$mdLastName")[0].value = "";
    }

    $('#mdSubmit').trigger("click");
  });

  $('.deleteCityFilter').click(function(){
    if((window.location.href).indexOf("ember-") > 0){
    document.getElementsByName("p$lt$ctl02$pageplaceholder$p$lt$ctl00$MemberDirectory$mdCity")[0].value = "";
    //fprovider
    }else{
    document.getElementsByName("p$lt$ctl02$pageplaceholder$p$lt$ctl00$FindAProvider$mdCity")[0].value = "";
    }

    $('#mdSubmit').trigger("click");
  });

  $('.deleteZipFilter').click(function(){
    if((window.location.href).indexOf("ember-") > 0){
    document.getElementsByName("p$lt$ctl02$pageplaceholder$p$lt$ctl00$MemberDirectory$mdZipCode")[0].value = "";
    //fprovider
    }else{
    document.getElementsByName("p$lt$ctl02$pageplaceholder$p$lt$ctl00$FindAProvider$mdZipCode")[0].value = "";
    }

    $('#mdSubmit').trigger("click");
  });

  $('.deleteRadiusFilter').click(function(){
    if((window.location.href).indexOf("ember-") > 0){
    document.getElementsByName("p$lt$ctl02$pageplaceholder$p$lt$ctl00$MemberDirectory$mdRadiusSelect")[0].value = "";
    //fprovider
    }else{
    document.getElementsByName("p$lt$ctl02$pageplaceholder$p$lt$ctl00$FindAProvider$mdRadiusSelect")[0].value = "";
    }

    $('#mdSubmit').trigger("click");
  });


})(jQuery);
