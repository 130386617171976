(function($, doc) {

  /**
   * implements jasny bootstrap off canvas
   * @see https://github.com/jasny/bootstrap
   *
   * <button type="button"
   *   class="btn nav-primary-toggle"
   *   data-toggle="offcanvas"
   *   data-target="#navPrimary"
   *   data-canvas="body">
   *   ...
   * </button
   *
   * <nav id="navPrimary"
   *   class="nav-primary navmenu navmenu-fixed-left offcanvas"
   *   role="navigation">
   *   ...
   * </ul
   */


  $('.close-alert').on('click', function() { // Class for the close button
    $(this).parent().slideUp(300); // Hide the CTA div
    sessionStorage['alertClosed'] = true;
  });

  var cookie = sessionStorage['alertClosed'];
  if (!cookie && $('.alert-wrap')) {
    $('.alert-wrap').addClass('show'); // IF Cookie
  }

  var $doc = $(document);
  // var $navPrimary = $('#navmenu');
  var $navPrimaryBtn = $('.nav-primary-toggle');

  // $navPrimary.offcanvas({
  //   canvas: true
  // });

  // toggle class on show and hide events
  $doc.on('show.bs.offcanvas', toggleNavPrimaryBtn);
  $doc.on('hide.bs.offcanvas', toggleNavPrimaryBtn);

  /**
   * Toggles class to show hamburger or X
   * @return {void}
   */
  function toggleNavPrimaryBtn() {
    $navPrimaryBtn.toggleClass('active');
  }





//#############################################################
//DIRECTORIES LOCATIONS
//#############################################################
// window.ACOEM = window.ACOEM || {};
// (function(o) {
//     var $latitude;
//     var $longitude;
//     var $address;
//     var $md = jQuery('.formSearch');
//     var $zip;
//     var $city;


//     //get values from form
//     if ($md.length) {
//       $zip = $md.find('#mdZipCode');
//       $zip = o.md.zipCode;

//       $city = $md.find('#mdCity');
//       $city = o.md.city;
//     }

//     //based on values create $address to pass into API
//     if($zip != ''){
//       if($city != ''){
//         $address = $city + " " + $zip;
//       }else{
//         $address = $zip
//       }
//     }else{
//       $address = $city
//     }

//     //get lat and long from provided address
//     if($address != ''){
//       var geocoder = new google.maps.Geocoder();
//       geocoder.geocode( { 'address': $address}, function(results, status) {
//         if (status == google.maps.GeocoderStatus.OK) {
//           $latitude = results[0].geometry.location.lat();
//           $longitude = results[0].geometry.location.lng();
//         } else {
//           console.log("Geocode was not successful for the following reason: " + status);
//         }
//         document.getElementById("mdLat").value = $latitude;
//         document.getElementById("mdLong").value = $longitude;

//         console.log('Latitude: ' + $latitude + ' Logitude: ' + $longitude);
//       });
//     }


//   })(window.ACOEM);


})(jQuery, document);







