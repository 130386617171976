/*
  Handles search filter counts and toggles
 */
(function($) {

  // generated counts on page load only, not dynamic
  var $filterListToggle = $('.filter-list-toggle');
  var $filterTabs = $('#filter-tabs').children('li');
  var totalChecked = 0;

  $filterTabs.each(function() {
    var checked = $(this).children('.filter-group').find('input:checked');
    if (checked.length) {
      totalChecked += checked.length;
      $(this).children('.filter-group-toggle').addClass('filters-selected')
        .children('.badge').text(checked.length);
    }
  });

  if (totalChecked) {
    $filterListToggle.addClass('filters-selected')
      .children('.badge').text(totalChecked);
  }

  // Handle toggle
  $('.toggle').click(function(e) {
    var $this = $(this);
    e.preventDefault();

    $this.parent().toggleClass('open');

    if ($this.attr('aria-expanded') === 'true') {
      $this.attr('aria-expanded', false);
    }
    else {
      $this.attr('aria-expanded', true);
    }

  });

})(jQuery);
